import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">

      <Container>
        <div className="text-center">

          <h2 className="main-heading"><span className="bluecolor">FAQ</span></h2>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is cryptocurrency wallet development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Cryptocurrency wallet development is the process of creating a top-notch wallet with enriched features and functionalities to secure cryptocurrencies.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How much does it cost to develop a cryptocurrency wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The estimated cost to develop a cryptocurrency wallet ranges between $15,000 to $20,000. Depending on various factors like customization, complexity of features, choice of development method, and others, the cost may vary.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                What are the benefits of cryptocurrency wallet development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The benefits of making your own cryptocurrency wallet for your website, mobile application, or browser extension will help user engagement and is an easy way to create branding for your business.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How long does it take to make your own cryptocurrency wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">With the White-label software, the cryptocurrency wallet can be created within 2 to 3 weeks. Rather, if you choose to develop it from scratch, the cryptocurrency wallet creation can take up to a year.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection