import React, { Component } from 'react'

class Addon extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
            Cryptocurrency Wallet Development</h3>
            <p className="sub-heading-bold mx-auto">These are the add-on features that have been incorporated into our crypto wallet development.
            </p>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                Staking
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                Customization
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                Swapping
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                Chrome Wallet Extension
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                User Chat
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs6')} >
                Crypto Asset Conversion
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs7')} >
                Bulk Transfer
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Staking
                      </h4>
                      <p className="pharagraph">Our crypto-wallet comes with the staking option where your users can hold their cryptocurrencies to raise profits. This helps you to gain more users and an add-on promotion for your wallet.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="418px" height="273px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/wallet-staking.png" alt="Staking" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Customization
                      </h4>
                      <p className="pharagraph">Our crypto-wallet comes with a customization option where you can modify the display, theme, login options, and others. Other than that, you can also change the transaction limit, fees, and many others.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="306px" height="271px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/wallet-customization.png" alt="Customization" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Swapping
                      </h4>
                      <p className="pharagraph">With our digital wallet, users can swap, sell, or buy cryptocurrencies without depending on an exchange. This also eliminates the external use of Bitcoin wallet integration.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="314px" height="281px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/wallet-swapping.png" alt="Swapping" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Chrome Wallet Extension</h4>
                      <p className="pharagraph">When you tend to buy our wallet, we also offer you the Chrome extension for your cryptocurrency wallet. Hence, by integrating the extension your users can access the wallet on desktops or computers.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="364px" height="286px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/wallet-chrome-wallet-extension.png" alt="Chrome Wallet Extension" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">User Chat
                      </h4>
                      <p className="pharagraph">One of the highly-demanding features of the wallet is the chatting or interaction option. This is to reduce miscommunication and theft. Our crypto-wallet comes along with the exponential feature.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="265px" height="260px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/user-chat-crypto-wallet.png" alt="User Chat" />
                    </div>
                  </div>
                </div>
                <div id="tabs6" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Crypto Asset Conversion</h4>
                      <p className="pharagraph">Our developer team offers you the Bitcoin wallet with the asset conversion feature. So, your users can convert their fiat to cryptocurrencies and vice versa within the wallet.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="277px" height="215px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/wallet-crypto-asset-conversion.png" alt="Crypto Asset Conversion" />
                    </div>
                  </div>
                </div>
                <div id="tabs7" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Bulk Transfer </h4>
                      <p className="pharagraph">Through the group transfer or airdrop, your users can send their digital assets to various recipients at the same time. Thus transactions are made fast with this feature.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="434px" height="310px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/wallet-bulk-transfer.png" alt="Bulk Transfer" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon