import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2">Cryptocurrency Wallet Development - <span className='bluecolor'>Overview</span></h2>
              </div>
            </div>
            <div className="text-center">
              <p className="pharagraph text-center">Cryptocurrency wallet development is a process of creating wallets to store, and manage digital assets for making safe crypto transactions. Crypto wallets are super popular in the crypto world and making one is not an easy task. The crypto wallet development process demands a deep understanding of blockchain technology, robust security measures, and a user-friendly design for grabbing users.
              </p>
              <p className="pharagraph text-center mb-0">Further, Our Blockchain wallet app development services are fine-tuned to suit aspiring entrepreneurs who want to prove their mettle in the industry. Coinsclone's cryptocurrency wallet comes with the best-in-class features, benefits and is open to customization. We additionally deliver exemplary solutions to create an e-wallet for digital payments in quick time at a budget-friendly price.  To discover excellence firsthand - connect with us!
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis