import React from 'react'


const FeaturesWallet = () => {


  return (
    <section className="choosedesire ychoose pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Features of </span>our Cryptocurrency</span>
            Wallet - Explore the Uniqueness</h3>
            <p className="sub-heading-bold mx-auto">Our premium crypto wallet solution to build a crypto wallet app includes the following features.
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/qr-code-scanner.png" alt="QR code scanner image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                QR Code Scanner
                </h3>
                <p className="pharagraph">
                This QR code scanner feature is an easy way to transact cryptocurrencies with just a single click. With this feature, the user’s crypto wallet address and public keys are scanned promptly.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3">
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload' width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/multiple-currency-support.png" alt="Multiple currency support image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Multiple Currency Support 
                </h3>
                <p className="pharagraph">
                One of the prominent features is multi-currency support, which enhances the user experience and makes it more user-friendly. This feature enhances user convenience by supporting different blockchain networks and tokens.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/transaction-history.png" alt="Transaction History image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Transaction History
                </h3>
                <p className="pharagraph">
                users can find out the previous withdrawal, deposit, send, and receive transaction history through this feature.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/automated-session-logout.png" alt="Automated Session Logout image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Automated Session Logout
                </h3>
                <p className="pharagraph">
                To protect users' assets and sensitive information, this feature automatically logs the user out of their wallet session after a certain period of inactivity. This helps to prevent unauthorized access and adds an extra layer of security to the cryptocurrency wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/real-time-conversion.png" alt="Real-Time Conversion Rates image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Real-Time Conversion Rates
                </h3>
                <p className="pharagraph">
                Our crypto-wallet comes along with this feature to know the live conversion rates of the assets in detail. Detailed price variations of the cryptocurrencies are explained through charts and graphs.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/referral.png" alt="Referral" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Referral
                </h3>
                <p className="pharagraph">
                Inviting and offering rewards for your users through referral programs would help to gain a high user count. You can also reap enough profits with this feature by inviting new users to use the digital wallet and earn rewards and incentives.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/cross-platform-compatibility.png" alt="Cross-platform compatibility" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Cross-Platform Compatibility
                </h3>
                <p className="pharagraph">
                This cross-platform compatibility feature in a cryptocurrency wallet will allow users to operate seamlessly across various devices and operating systems, such as Windows, macOS, iOS, and Android. Hence, your users can access and manage their digital assets from desktops, mobile devices, and web browsers.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/push-notifications.png" alt="Push notifications" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Push Notifications
                </h3>
                <p className="pharagraph">
                Our Bitcoin wallet development has this exemplary feature to provide regular updates regarding crypto assets and transactions. Users can optimize alerts for the token’s price value changes and the  market fluctuations by enabling a push notification feature.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/ledger-wallet-integration.png" alt="Ledger Wallet Integration" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Ledger Wallet Integration
                </h3>
                <p className="pharagraph">
                This feature connects ledger devices and blockchain technology. It provides a user-friendly interface for managing and transacting cryptos. Additionally, it supports backup and recovery options to protect assets.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img className='lazyload'">
                  <img className='lazyload'  width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/two-factor-authentication.png" alt="Two-Factor Authentication" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Two-Factor Authentication
                </h3>
                <p className="pharagraph">
                Your user's cryptocurrencies are protected by a robust two-factor authentication method. This method requires biometrics, PINs, or passwords to log in to a bitcoin wallet, thus ensuring only authorized access.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesWallet