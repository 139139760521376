import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class SecurityFeature extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod mb-0">
        <div className="container"> 
          <h2 className="heading-h2 text-center">
            <span className="bluecolor">Security Features</span> of Our Cryptocurrency Wallet  Software
          </h2>
          <p className="sub-heading-bold">
          We prioritize wallet security above everything and never compromise on security measures. Get to know about our High-end security features right here.
          </p>
          <div className="d-lg-flex">
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/biometric.png"
                  alt="Biometric Authentication image1"
                />
                <span>Biometric Authentication</span>
              </h3>
              <p className="pharagraph">
              We've incorporated a security feature called Biometric or Fingerprint authentication. Through this, only registered users can log in to the account.
              </p>
            </div>
            <div className="square">
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/pincode.png"
                  alt="Pin code or password protection image1"
                />
                <span>Pin Code or Password Protection</span>
              </h3>
              <p className="pharagraph">
              This is an alternative to fingerprint protection since users can integrate a PIN or password for the bitcoin wallet.
              </p>
            </div>
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/encrypted.png"
                  alt="Encrypted transaction image1"
                />
                <span>Encrypted Transaction</span>
              </h3>
              <p className="pharagraph">
              Through this encrypted transaction, your user’s wallet or public address is restricted from usage of any third-party members or by hackers.
              </p>
            </div>
          </div>
          <div className="d-lg-flex">
          
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/browser.png"
                  alt="Browser detection security image1"  
                />
                <span>Browser Detection Security</span>
              </h3>
              <p className="pharagraph">
              Your users can deduct any threat or virus-associated websites easily with this security feature and cut them off easily.
              </p>
            </div>
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/database.png"
                  alt="Database encryption image1"
                />
                <span>Database Encryption</span>
              </h3>
              <p className="pharagraph">
              With this security feature, only authorized or registered users can get access to the wallet data.
              </p>
            </div>
          </div>
          <div className="d-lg-flex">
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/anti-phishing.png"
                  alt="Anti Phishing protocols image1"
                />
                <span>Anti Phishing Protocols</span>
              </h3>
              <p className="pharagraph">
              Before any website scams or threats, this feature helps your users safeguard their digital assets.
              </p>
            </div>
            <div className="square">
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/key-managment.png"
                  alt="Key management server image1"
                />
                <span>Key Management Server</span>
              </h3>
              <p className="pharagraph">
              The public and private keys are securely stored in the cryptocurrency wallet for future use. This key management feature helps to prevent theft or loss of cryptos.
              </p>
            </div>
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/thread.png"
                  alt="Threat protection image1"
                />
                <span>Threat Protection</span>
              </h3>
              <p className="pharagraph">
              Even if any website or hacker party leaves a threat, the wallet’s support system helps to overcome them easily.
              </p>
            </div>
          </div>
          <div className="mt-3 text-center">
              <ButtonComponent />
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default SecurityFeature
