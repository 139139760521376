import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="main-heading">Our Standard Cryptocurrency Wallet <div className='bluecolor'>Development Approach</div></h2>
          <p className="sub-heading-bold mx-auto">Coinsclone has the best development procedures to create a centralized or decentralized wallet for web extension, desktop, and app.</p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/paxful-new/requirment-and-planning.png"
                  alt="Development and Approach"
                  width={310}
                />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirement Gathering</h5>
            <p className="pharagraph" >It is the foremost step we proceed with for all our reputed clients while developing your Bitcoin wallet. Our team of experts will gather the requirements and suggest the best solution that matches your ideal business needs.
            </p>
            <br />
            <h5 >Planning </h5>
            <p className="pharagraph">We create a wallet for cryptocurrencies with a proper plan, we derive a well-structured roadmap for the finest cryptocurrency wallet app development outcome as per the requirements of the customers. Thus we plan to execute without any hassle.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >UI/UX Designing</h5>
            <p className="pharagraph" >Coinsclone has dozens of creative minds to make a user-friendly wallet. Our skilled design team will outline based on the demands of the clientele. We move to the next stage only after the client is satisfied with the UI/UX part.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">In the development phase, our experienced cryptocurrency wallet developers will work on the backend part and optimize the entire wallet by enabling the latest functionalities and security mechanisms.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
          <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/paxful-new/design-development.png"
                  alt="Designing and development"
                  width={369}
                />
            </div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/paxful-new/testing-and-deployment.png"
                  alt="Testing and deployment"
                  width={295}
                />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5>Testing</h5>
            <p  className="pharagraph">We have a talented testing team to check the quality of the Blockchain wallet development. Our top-notch QA team will conduct rigorous testing multiple times to deliver an impeccable product outcome.
            </p>
            <br />
            <h5>Deployment</h5>
            <p  className="pharagraph mb-0">Once completing all these phases of developing a crypto wallet, we will securely deliver the product and make your own Bitcoin wallet app or website extension on the clientele’s desired server swiftly.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach