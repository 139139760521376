import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
              <div className="innerContent">
                <h1 className="main-heading"><span className='bluecolor'>Cryptocurrency</span> Wallet Development Company</h1>
                <p className="pharagraph">Our cryptocurrency wallet development services allows budding entrepreneurs like you to make a customized non-custodial crypto wallet. Our team of developers focuses on making top-notch wallets with lots of useful features. Step into the world of digital currency with our wallet development services!</p>
                <p className="pharagraph">Get a Free Consultation from industry experts now.</p>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/cryptocurrency-wallet-development-company.webp"
                  alt="Cryptocurrency Wallet Development Company banner"
                  width={743}
                  className='d-none d-lg-block'
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection