import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerSection from "../components/Cryptowallet/Banner";
import Futuristic from "../components/Cryptowallet/Futuristic";
import Pickcrypto from "../components/Cryptowallet/Pickcrypto";
import Whychoose from "../components/Cryptowallet/Whychoose";
import TechStack from "../components/Cryptowallet/TechStack";
import UserFriendly from "../components/Cryptowallet/UserFriendly";
import FaqSection from '../components/Cryptowallet/FaqSection';
import FeaturesWallet from '../components/Cryptowallet/FeaturesWallet';
import SecurityFeature from '../components/Cryptowallet/SecurityFeature';
import Addon from '../components/Cryptowallet/Addon';
import DevApproach from '../components/Cryptowallet/DevApproach';



const Cryptowallet = () => (  
  <section className="cryptoex">
    <Layout>
    <link rel="preload" fetchpriority="high" as="image" href="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/crypto-wallet-development-company.webp" type="image/webp"></link> 
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/cryptocurrency-wallet-development-company/" />
        <title>Cryptocurrency Wallet Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is a best cryptocurrency wallet development company that offers secure, customizable crypto wallet development solutions at affordable price" />
        <meta name="keywords" content="Cryptocurrency Wallet Development Company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cryptocurrency Wallet Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is a best cryptocurrency wallet development company that offers secure, customizable crypto wallet development solutions at affordable price" />
        <meta property="og:url" content="https://www.coinsclone.com/cryptocurrency-wallet-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/cryptowallet/crypto-wallet-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a best cryptocurrency wallet development company that offers secure, customizable crypto wallet development solutions at affordable price" />
        <meta name="twitter:title" content="Cryptocurrency Wallet Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/cryptowallet/crypto-wallet-development.png" />
      </Helmet>
      <BannerSection />
      <div className="breadcrumb mb-0 wallet-banner">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Cryptocurrency Wallet Development Company </span>
        </div>
      </div>
      <Futuristic />
      <Pickcrypto />
      <FeaturesWallet />
      <SecurityFeature />
      <Addon />
      <DevApproach />
      <Whychoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Cryptowallet
