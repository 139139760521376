import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'




class Whychoose extends React.Component {

  render() {
    return (
      <section className='whychoose cryptwall pt-100'>
        <div className="container">
          <div className="text-center">
            <h2 className="main-heading mw1030"><span className='bluecolor'>Why Choose Coinsclone</span> for your Cryptocurrency Wallet Development partner?</h2>
            <p className="pharagraph text-center">
            Coinsclone is an eminent and market-leading Crypto wallet development Company in the crypto space. Being a pioneer cryptocurrency exchange development company for more than 7 years, we specialize in offering outstanding Bitcoin wallet app development services for all blooming startups. We use state-of-the-art technology tools to build a cryptocurrency wallet, which helps your users to transfer virtual assets in no time. Coinsclone earned a solid reputation by providing exemplary tailored Bitcoin wallet services depending on the needs and demands of the clients. We promise that the product we deliver to you will be utterly packed with appealing attributes and shielded with rigorous security functions. Our developer team will never let you down and will always deliver top-notch Cryptocurrency wallet development services for clients.
            </p>
            <div className="row table-content mw1030 m-auto">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <ul>
              <li ><p>50+ well-trained blockchain professionals.</p></li>
              <li ><p>On-time product delivery.</p></li>
              <li ><p>Successfully deployed 100+ cryptocurrency wallets on various chains.</p></li>
              <li ><p>Masters in creating a highly responsive and scalable cryptocurrency wallet.</p></li>
              <li ><p>Round-the-clock technical and customer support.</p></li>
              <li ><p>We have a dedicated team to respond expeditiously and solve your queries</p></li>
            </ul>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/why-choose-crypto-wallet.png"
                  alt="Why Choose Crypto Wallet Development"
                  width={475}
                />
            </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Whychoose